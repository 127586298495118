const cards = [
	document.getElementById("hover1"),
	document.getElementById("hover2"),
	document.getElementById("hover3"),
];

const accessories = [
	document.getElementById("acc1"),
	document.getElementById("acc2"),
	document.getElementById("acc3"),
	document.getElementById("acc4"),
	document.getElementById("acc5"),
	document.getElementById("acc6"),
];

const handleHover = (event) => {
	event.currentTarget.classList.add("text-white");
	event.currentTarget.querySelector("picture").classList.add("invert");
};

const handleOut = (event) => {
	event.currentTarget.classList.remove("text-white");
	event.currentTarget.querySelector("picture").classList.remove("invert");
};

for (const card of cards) {
	card.addEventListener("mouseover", handleHover);
	card.addEventListener("mouseout", handleOut);
}

const handleHoverAccessories = (event) => {
	event.currentTarget.querySelector("a").classList.remove("lg:bg-whatsapp");
};

const handleOutAccessories = (event) => {
	event.currentTarget.querySelector("a").classList.add("lg:bg-whatsapp");
};

for (const accessory of accessories) {
	accessory.addEventListener("mouseover", handleHoverAccessories);
	accessory.addEventListener("mouseout", handleOutAccessories);
}
